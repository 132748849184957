import React from 'react'; // Импортируем React
import { useSearchParams } from 'react-router-dom'; // Импортируем хук для работы с параметрами поиска в URL
import VideoPlayer from './VideoPlayer'; // Импортируем компонент видеоплеера

const VideoPlayerPage = () => {
    const [searchParams] = useSearchParams(); // Получаем параметры поиска из URL
    const videoUrl = searchParams.get('url'); // Извлекаем параметр `url` из строки запроса

    // Проверяем, указан ли параметр `url`
    if (!videoUrl) {
        // Если параметр не указан, возвращаем сообщение об ошибке
        return <div>URL видео не указан!</div>;
    }

    // Если параметр указан, рендерим компонент VideoPlayer с полученным URL
    return <VideoPlayer src={videoUrl} />;
};

export default VideoPlayerPage; // Экспортируем компонент VideoPlayerPage для использования в других частях приложения