import React, { useEffect, useRef } from 'react'; // Импортируем React и необходимые хуки
import mpegts from 'mpegts.js'; // Импортируем библиотеку для работы с MPEG-TS потоками

const VideoPlayer = ({ src }) => {
    const videoRef = useRef(null); // Создаем реф для доступа к элементу video

    useEffect(() => {
        // Проверяем, поддерживает ли браузер MSE (Media Source Extensions) для потокового воспроизведения
        if (mpegts.getFeatureList().mseLivePlayback) {
            // Создаем экземпляр плеера с указанием типа и URL источника
            const player = mpegts.createPlayer({
                type: 'mpegts', // Указываем тип потока
                url: src, // URL источника видео
            });

            // Привязываем плеер к элементу video
            player.attachMediaElement(videoRef.current);
            player.load(); // Загружаем поток
            player.play(); // Начинаем воспроизведение

            // Функция очистки при размонтировании компонента
            return () => {
                player.destroy(); // Уничтожаем плеер, чтобы освободить ресурсы
            };
        }
    }, [src]); // Эффект срабатывает при изменении src

    // Возвращаем элемент video с рефом и настройками
    return <video ref={videoRef} autoPlay muted style={{ width: '100%', height: 'auto' }} />;
};

export default VideoPlayer; // Экспортируем компонент VideoPlayer для использования в других частях приложения