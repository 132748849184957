import React from "react";
import { YMInitializer } from "react-yandex-metrika"; // Импортируем компонент для инициализации Яндекс.Метрики
import { Routes, Route } from "react-router-dom"; // Импортируем компоненты для работы с маршрутизацией
import Main from "../Main/Main"; // Импортируем главный компонент приложения
import DeleteUser from "../DeleteUser/DeleteUser"; // Импортируем компонент для удаления пользователя
import VideoPlayerPage from "../VideoPlayerPage/VideoPlayerPage"; // Импортируем компонент видеоплеера

const App = () => {
  return (
    <>
      {/* Инициализация Яндекс.Метрики с указанным аккаунтом */}
      <YMInitializer accounts={[89196362]} />
      
      {/* Определение маршрутов приложения */}
      <Routes>
        {/* Главная страница приложения */}
        <Route path="/" element={<Main />} />
        
        {/* Страница для удаления пользователя с типом "mpi" */}
        <Route path="/deluser" element={<DeleteUser type="mpi" />} />
        
        {/* Страница для удаления исполнителя с типом "mpj" */}
        <Route path="/delexecutor" element={<DeleteUser type="mpj" />} />
        
        {/* Страница видеоплеера */}
        <Route path="/videoplayer" element={<VideoPlayerPage />} />
      </Routes>
    </>
  );
};

export default App; // Экспортируем компонент App для использования в других частях приложения