import React from "react";
import { Link } from "react-router-dom";

import siteLogo from "../vendor/images/icons/siteLogo.png";
import mapMetk from "../vendor/images/icons/mapMetk.png";
import teleph from "../vendor/images/icons/teleph.png";
import email from "../vendor/images/icons/email.png";
// import fbIcon from "../vendor/images/icons/facebookIcon.svg";
// import vkIcon from "../vendor/images/icons/vkIcon.svg";
// import igIcon from "../vendor/images/icons/igIcon.svg";

import "./Footer.css";

const App = () => {
  return (
    <footer className="footer">
      {/* <ul className="footer__links-list">
        <li>
          <a>
            <img src={fbIcon} alt="Иконка Facebook" className="footer__link-icon"/>
          </a>
        </li>
        <li>
          <a>
            <img src={vkIcon} alt="Иконка Вконтакте" className="footer__link-icon"/>
          </a>
        </li>
        <li>
          <a>
            <img src={igIcon} alt="Иконка Instagram" className="footer__link-icon"/>
          </a>
        </li>
      </ul> */}
      <div className="footer__privacy-link-container">
        <div className="footer__container" style={{ display: "flex"}}>
        <a href="#top">
            <img
              src={siteLogo}
              alt="Логотип ЖКХ-Инфо"
              className="footer_logo_left"
            />
          </a>
          <p className="footer__copyright footer_text_name_left">ЖКХ.Инфо</p>
        </div>
        <div className="footer__container center">
        
        </div>
        <img
              src={mapMetk}
              alt="Иконка метки на карте"
              className="footer_logo_right_tel_map_email"
            />
        <div className="footer__container">
          <p className="footer__adress_new"> 660135 г. Красноярск</p>
          <p className="footer__adress_new">ул. Взлетная 7а, офис 164</p>
          </div>
          <img
              src={teleph}
              alt="Иконка телефона"
              className="footer_logo_right_tel_map_email"
            />
        <div className="footer__container">
          <a className="footer__tel_email" href="tel:+79029268824">+ 7 (902) 926-88-24</a>
        </div>
        <img
              src={email}
              alt="Иконка почты"
              className="footer_logo_right_tel_map_email"
            />
        <div className="footer__container">
          <a className="footer__tel_email" href="mailto:mpsupport@kras-abs.ru">mpsupport@kras-abs.ru</a>
        </div>
      </div>
      <div className="footer__privacy-link-container">
        <Link className="footer__privacy-link" to="/privacy" target="_blank">
          Политика обработки персональных данных
        </Link>
        <p className="footer__privacy-text_copirite"> &copy; ООО "ЖКХ Инфо Красноярск" (Группа компаниий АБС), {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
};

export default App;
